@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.new_ui_banner.new_dashboard {
	font-family: "Inter", sans-serif;
	position: fixed;
	top: 0;
	z-index: 1000;
}

.dashboard_layout aside.dashboard_sidebar.icon_sidebar + .dashboard_content {
	width: calc(100% - 80px);
	height: 100vh;
	min-height: 100vh;
	overflow-y: hidden;
	&.budget_tracker {
		overflow-y: scroll;
	}
}

.dashboard_layout {
	width: 100%;
	display: flex;
	flex-direction: row;
	height: 100vh;
	overflow-y: scroll;
	position: relative;
	&.banner_info {
		top: 40px;
	}
	aside.dashboard_sidebar {
		height: 100vh;
		box-shadow: 0px 1px 2px 0px #0000000d;
		background-color: #fafafc;
		width: 240px;
		padding: 0px;
		z-index: 10;
		transition: width ease-in-out 0.5s;
		min-width: 240px;
		position: fixed;
		&.icon_sidebar {
			width: 75px;
			min-width: 75px;
			.drawerBtn {
				position: absolute;
				right: -12px;
				z-index: 1000;
			}
			.sidebar_link_group .sidebar_section a.active.nav_link {
				background-color: transparent;
				border-radius: 8px;
				border: 2px solid #e72e50;
				justify-content: center;
				padding: 0;
			}
			.event_switcher {
				.event_switch_view {
					width: fit-content;
					.current_event_group p,
					.update_event {
						display: none;
					}
				}
				.event_menu_list {
					.event_switch_view {
						p {
							display: flex;
						}
					}
					svg {
						right: unset;
						left: 10px;
					}
				}
			}
		}
		&.full_sidebar {
			width: 240px;
			min-width: 240px;
		}
		&.icon_sidebar {
			& + .dashboard_content .dashboard_header {
				width: 100%;
				padding-right: 120px;
				@media (max-width: 768px) {
					padding-right: 100px;
				}
			}
			.more_links .current_event {
				justify-content: center;
				button.update_event,
				p {
					display: none;
				}
			}
		}
		.sidebar_top {
			width: 100%;
			padding: 1rem 1rem 1rem 1.5rem;
			display: flex;
			align-items: center;
			height: 60px;
			justify-content: space-between;
			border-bottom: 1px solid #5455660d;
			svg {
				color: #828282;
				height: 16px;
				width: 16px;
			}
			img {
				height: 20px;
			}
			button {
				background-color: transparent;
				border: none;
			}
		}
		.sidebar_link_group {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 90%;
			.sidebar_section {
				border-bottom: solid #5455660d;
				padding: 1rem 1rem 0.4rem 1rem;
				border-bottom-width: 1px;
				&.more_section {
					border-bottom: unset;
					a.nav_link {
						.sidebar_icon {
							background-color: white;
						}
					}
				}
				&.overview {
					a.nav_link .sidebar_icon {
						background-color: transparent;
						border: 1px solid #5455660d;
					}
				}

				a.nav_link {
					height: 40px;
					background-color: transparent;
					display: flex;
					padding-left: 10px;
					cursor: pointer;
					color: #404040;
					display: flex;
					gap: 10px;
					font-family: "Inter", sans-serif;
					font-size: 0.875rem;
					font-weight: 500;
					line-height: 1.2rem;
					width: 100%;
					letter-spacing: -0.025em;
					text-align: left;
					.sidebar_icon {
						// background: #ffffff;
						height: 24px;
						width: 24px;
						border-radius: 4px;
						padding: 6px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					svg {
						height: 16px;
						width: 16px;
					}
					.beta {
						font-size: 8px;
						border: 0.5px solid #888c8f;
						border-radius: 2px;
						padding: 0px 2px;
						height: 14px;
						font-weight: 400;
						color: #888c8f;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				a:hover,
				a.active.nav_link {
					background-color: #fff;
					border-radius: 2px;
					text-decoration: none;
					color: #404040;
				}
			}
			.more_links {
				border-top: 1px solid #5455660d;
				margin-bottom: 50px;
			}
			.sidebar_section {
				border-bottom: none;
			}
			h6 {
				font-family: "Inter", sans-serif;
				font-size: 9px;
				font-weight: 400;
				line-height: 9px;
				letter-spacing: -0.025em;
				text-align: left;
				padding-left: 10px;
				color: #888c8f;
			}
			.sidebar_links {
				display: flex;
				flex-direction: column;
				gap: 0px;
				padding: 0;
				margin: 0px;
				color: #828282;
				a {
					height: 40px;
					background-color: transparent;
					display: flex;
					padding-left: 10px;
					color: #404040;
					display: flex;
					gap: 10px;
					font-family: "Inter", sans-serif;
					font-size: 0.875rem;
					font-weight: 500;
					line-height: 1.2rem;
					letter-spacing: -0.025em;
					text-align: left;
					svg {
						height: 12px;
						width: 12px;
					}

					&:nth-child(1) .sidebar_icon {
						background-color: #e7eeff;
					}

					&:nth-child(2) .sidebar_icon {
						background-color: #defdee;
					}

					&:nth-child(3) .sidebar_icon {
						background-color: #fbfcb6;
					}

					&:nth-child(4) .sidebar_icon {
						background-color: #fee4e5;
					}

					&:nth-child(5) .sidebar_icon {
						background-color: #f1cbfe;
					}
				}

				a:hover {
					background-color: #fff;
					border-radius: 2px;
					text-decoration: none;
				}
			}
		}
		.event_switcher {
			position: relative;

			.event_switch_view {
				border: 0.5px solid #e9e9e9;
				background-color: #fff;
				padding: 8px 12px;
				margin: 16px;
				width: 205px;
				height: 36px;
				cursor: pointer;
				display: flex;
				align-items: center;
				border-radius: 6px;
				justify-content: space-between;
				@media (max-width: 560px) {
					width: 90%;
				}
				.current_event_group {
					display: flex;
					align-items: center;
					gap: 5px;
				}
				.initials {
					border-radius: 4px;
					height: 24px;
					width: 24px;
					min-width: 24px;
					min-height: 24px;
					background-color: #fff;
					color: #031729;
					font-weight: 500;
					font-size: 13px;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 0px 4.67px 23.33px 0px #0000000d;
				}
				p {
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					font-weight: 600;
					line-height: 14.52px;
					margin: 0 6px;
					text-wrap: nowrap;
					white-space: nowrap;
					flex-wrap: wrap;
					text-overflow: ellipsis;
					max-width: 110px;
					overflow: hidden;
				}
				.update_event {
					display: flex;
					flex-direction: column;
					background: #fafafc;
					margin: 0;
					padding: 0px;
					border: none;
					border-radius: 6px;
					height: 24px;
					width: 24px;
					color: #404040;
					justify-content: center;
					align-items: center;
					svg {
						height: 12px;
						width: 12px;
						transform: rotate(90deg);
					}
				}
			}

			menu.event_menu_list {
				position: absolute;
				bottom: 40px;
				left: 20px;
				padding: 0;
				background-color: white;
				border: 0.5px solid #dddfe380;
				box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
				width: 205px;
				padding: 4px;
				gap: 6px;
				border-radius: 6px;
				border: 0.5px;
				ul {
					padding: 0;
					display: flex;
					flex-direction: column;
					gap: 5px;
					// border-bottom: 1px solid #5455660d;
					padding-bottom: 15px;
					margin: 0;
					li {
						list-style: none;
						padding: 0;
					}
					.event_switch_view {
						margin: 0px;
						background-color: #fbfbfb;
						border: 0px;
						min-width: unset;
						width: 100%;
						padding: 6px;
						p {
							text-align: left;
							min-width: 140px;
						}
					}
				}

				.all_events_view {
					height: 34px;
					display: flex;
					align-items: center;
					h6 {
						font-family: "Inter", sans-serif;
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						letter-spacing: -0.025em;
						text-align: left;
						color: #e72e50;
						padding-bottom: 0;
						margin-bottom: 0;
					}
				}
				svg {
					bottom: -10px;
					position: absolute;
					right: 10px;
				}
			}
		}
		@media (max-width: 1200px) {
			width: 180px;
			min-width: 180px;
			&.full_sidebar {
				width: 180px;
				min-width: 180px;
			}
		}
	}

	.dashboard_content {
		display: flex;
		width: calc(100% - 240px);
		transition: width ease-in-out 0.5s;
		flex-direction: column;
		background-color: white;
		padding: 0;
		position: absolute;
		top: 0;
		right: 0;
		&.ceremony_floor_plan header {
			z-index: 2000;
		}
		.loading_view {
			height: 100%;
			width: 100%;
		}
		.dashboard_header {
			border-bottom: 1px solid #5455660d;
			width: calc(100% - 240px);
			padding: 14px 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			position: fixed;
			height: 60px;
			z-index: 1000;
			.header_left {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 20px;
				.button_group {
					display: flex;
					align-items: center;
					gap: 5px;
					button {
						border-radius: 3px;
						border: 1px solid #5455660d;
						padding: 0px 5px;
						background-color: #fff;
						svg {
							height: 14px;
							width: 14px;
							stroke: #9395aa;
						}
					}
				}
				h3 {
					color: #000;
					font-size: 1rem;
					margin: 0;
					font-family: "Inter", sans-serif;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;

					margin-bottom: 5px;
					span {
						color: #000;
					}
				}
				p {
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					font-weight: 400;
					line-height: 12.1px;
					letter-spacing: -0.025em;
					padding: 0;
					margin: 0;
					text-align: left;
				}
				@media (max-width: 768px) {
					h3 {
						font-size: 0.8125rem;
					}
				}
			}
			.header_right {
				display: flex;
				flex-direction: row;
				gap: 10px;
				align-items: center;
			}
			.search_wrapper {
				position: relative;
				background: #f7f7f7;
				border-radius: 6px;
				padding: 10px 12px;
				color: #9395aa;
				font-size: 12px;
				font-family: "Inter", sans-serif;
				font-weight: 500;
				display: flex;
				align-items: center;
				svg {
					height: 14px;
					width: 14px;
				}
				input {
					border: none;
					background-color: transparent;
					width: 300px;
					letter-spacing: -0.025em;
					line-height: 16.8px;
					margin-left: 5px;
					font-family: "Inter", sans-serif;
					font-weight: 500;
					::placeholder {
						color: #9395aa;
					}
				}
				@media (max-width: 768px) {
					input {
						width: 140px;
					}
				}
			}
			.control_group {
				display: flex;
				align-items: center;
				background-color: #fbfbfb;
				padding: 6px 0px 6px 6px;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				span {
					background: #ffffff;
					box-shadow: 0px 4px 20px 0px #0000000d;
					border-radius: 4px;
					height: 24px;
					width: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 13px;
					font-weight: 500;
					color: #031729;
				}
				button {
					border: none;
					font-size: 0.875rem;
					font-family: "Inter", sans-serif;
					font-weight: 500;
					background-color: transparent;
				}
				svg {
					stroke: #888c8f;
					height: 12px;
					width: 12px;
				}
				.control_group_dropdown {
					position: absolute;
					top: 40px;
					background-color: #fff;
					display: flex;
					flex-direction: column;
					right: 0;
					min-width: 160px;
					align-items: flex-start;
					padding: 4px;
					border-radius: 6px;
					box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
					border: 0.5px solid #dddfe380;
					button,
					a {
						font-family: "Inter", sans-serif;
						font-size: 12px;
						line-height: 18px;
						letter-spacing: -0.025em;
						text-align: center;
						display: flex;
						color: #404040;
						font-weight: 500 !important;
						text-decoration: none;
						align-items: center;
						padding: 8px;
						width: 100%;
						&:hover {
							background: #fbfbfb;
							position: relative;
							&::after {
								content: url("../assets/img/checkIconRed.svg");
								position: absolute;
								right: 5px;
								transform: scale(0.7);
							}
						}
						span {
							text-wrap: nowrap;
							white-space: nowrap;

							height: unset;
							width: unset;
							background: transparent;
							box-shadow: none;
							&.app_version {
								color: #26cf9f;
								font-family: "Inter", sans-serif;
								font-size: 11px;
								font-weight: 500;
								line-height: 13.75px;
							}
						}
						svg {
							margin-right: 6px;
							stroke: #404040;
						}
						&.logout {
							margin-top: 10px;
							border-top: 1px solid #5455660d;
							justify-content: space-between;
							&:hover {
								&::after {
									display: none;
								}
							}
						}
					}
				}
			}

			@media (max-width: 768px) {
				padding: 14px 25px !important;
				width: calc(100% - 180px);
			}
			@media (max-width: 560px) {
				.header_left {
					display: none;
				}
				.control_group button:first-child {
					display: none;
				}
			}
		}

		main.dashboard_main {
			padding: 30px 40px;
			background-color: #fff;

			.dashboard_content {
				width: 100%;
				padding: 40px;
				top: unset;
				margin-top: 10px;
			}
			h1 {
				font-family: "Inter", sans-serif;
				font-size: 1.125rem;
				font-weight: 500;
				line-height: 25.2px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #000;
			}
			.checklist_tab_button_group {
				border-bottom: 1px solid #5455660d;
				width: 100%;
				height: 36px;
				display: flex;
				align-items: center;

				button {
					border: none;
					background-color: transparent;
					font-family: "Inter", sans-serif;
					font-size: 12px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: -0.02em;
					text-align: left;
					gap: 6px;
					height: 36px;
					display: flex;
					align-items: center;
					svg {
						height: 14px;
						width: 14px;
					}
					&.active {
						color: #fbbc05;
						border-bottom: 1px solid #fbbc05;
					}
				}
			}
		}

		@media (max-width: 1200px) {
			width: calc(100% - 180px);
			main.dashboard_main {
				.dashboard_content {
					padding: 40px 30px;
				}
			}
		}
		@media (max-width: 768px) {
			width: calc(100% - 180px);
			main.dashboard_main {
				.dashboard_content {
					padding: 40px 0px;
					section.ceremony_floor_plan .top_row .left_section .ceremonies_tab {
						max-width: 600px;
					}
				}
			}
		}
	}

	&.tablet__view {
		aside.dashboard_sidebar {
			align-items: center;
			.event_switcher .event_switch_view {
				display: none;
			}
			.sidebar_top {
				border: none;
				width: 100px;
				width: fit-content;
				height: unset;

				button {
					display: none;
				}
			}
			.sidebar_link_group_content_wrapper {
				display: flex;
				flex-direction: row;
				background-color: #fafafc;
			}
			.sidebar_link_group {
				background-color: #fafafc;
				flex-direction: row;
				height: unset;
				justify-content: unset;
				.more_links {
					margin-bottom: 0px;
					display: flex;
					border: none;
				}
				.sidebar_section {
					display: flex;
					flex-direction: row;
					padding: unset;
					a.nav_link {
						font-size: 12px;
						white-space: nowrap;
						height: unset;
						background-color: #fafafc !important;
						padding: 10px;
						height: 40px;
						&.active {
							background-color: #fff !important;
							height: 40px;
							border-radius: 10px;
						}
					}
				}
				h6 {
					display: none;
				}
				.sidebar_links {
					flex-direction: row;
				}
			}
			&.full_sidebar {
				width: 100%;
				min-width: 100%;
				height: 60px;
				position: unset;
				display: flex;
			}
		}

		.dashboard_header_top_content {
			width: 100%;
			height: 100px;
			position: fixed;
			z-index: 1000;
			display: flex;
			flex-direction: column;
		}
		.dashboard_content {
			width: 100%;
			.dashboard_header {
				width: 100%;
				padding: 14px 15px;
				position: unset;
			}
			main.dashboard_main .dashboard_content {
				padding: 40px 15px;
				padding-top: 90px;
			}
		}
	}
	@media (max-width: 320px) {
		top: 60px;
	}
	@media (max-width: 768px) {
		aside.dashboard_sidebar.mobile_view.full_sidebar .sidebar_top a img {
			height: 32px;
			width: 32px;
		}
	}
}

.driver-popover {
	border: 0.5px solid #e72e50;
	border-radius: 16px;
	padding: 0;
	font-family: "Inter", sans-serif;
	box-shadow: 10.95px 0px 43.78px 0px #0000001a;

	header.driver-popover-title {
		height: 56px;
		background: #fbfbfb;
		padding: 12px 24px;
		font-size: 14px;
		font-weight: 500;
		line-height: 19.6px;
		letter-spacing: -0.025em;
		text-align: center;
		margin: 0;
		display: flex !important;
		align-items: center;
		justify-content: space-between;
		margin: auto;
		border: 1px solid #5455660d;
		border-radius: 16px 16px 0px 0px;
		span {
			color: #e72e50;
			margin: 0px 1px;
		}
		div img {
			height: 32px;
			width: 32px;
			border-radius: 50%;
			object-fit: cover;
			margin-right: 5px;
			border: 1.02px solid #e72e50;
		}
	}

	.driver-popover-description {
		padding: 12px 24px;
		font-size: 13px;
		font-weight: 400;
		line-height: 18.2px;
		letter-spacing: -0.025em;
		text-align: left;
		color: #031729;
	}

	footer.driver-popover-footer {
		padding: 12px 24px;
		border-top: 1px solid #f4f3ff80;

		.driver-popover-navigation-btns {
			gap: 6px;

			button.driver-popover-next-btn {
				height: 32px;
				background: #e72e50;
				color: #fff;
				border-radius: 6px;
			}

			button {
				height: 32px;
				border-radius: 6px;
				padding: 0px 10px;
				border: 1px solid #5455660d;
				font-size: 12px;
				font-weight: 500;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #031729;
				text-shadow: none;
			}
		}
	}

	button.driver-popover-close-btn {
		color: #031729;
		top: 14px;
		right: 10px;
		font-size: 20px;
	}
}

*::-webkit-scrollbar {
	display: none;
}

@media (max-width: 768px) {
	.dashboard_layout {
		header {
			justify-content: space-between;
			a.logo,
			img.logo-img {
				height: 32px;
				width: 32px;
			}
			.header_button_group {
				display: flex;
				align-items: center;
				gap: 10px;
				button {
					border: 1px solid #5455660d;
					padding: 8px 10px;
					height: 32px;
					width: 32px;
					background: #fbfbfb;
					display: flex;
					border-radius: 6px;
					align-items: center;
					svg {
						height: 14px;
						width: 14px;
						stroke: #000;
					}
				}
			}
		}
		aside.dashboard_sidebar {
			width: 100%;
			top: 60px;

			&.icon_sidebar {
				display: none;
				+ .dashboard_content {
					width: 100%;
					margin: 0;
				}
				menu.event_menu_list ul .event_switch_view {
					width: fit-content;
					.current_event_group p,
					.update_event {
						display: none;
					}
				}
			}
			&.full_sidebar {
				width: 100%;
				min-width: 100%;
				z-index: 1000;
				position: fixed;
				top: 0;
				left: 0;
				&.mobile_view {
					.drawerBtn {
						border: 1px solid #5455660d;
						border-radius: 6px;
						padding: 8px 10px;
						height: 32px;
						width: 32px;
						align-items: center;
						display: flex;
						svg {
							height: 10px;
							width: 10px;
						}
					}
				}
			}

			.current_event {
				justify-content: space-between;
			}

			.sidebar_link_group .more_links {
				margin-bottom: 30px;
			}
		}
		.dashboard_content {
			width: 100%;
			.dashboard_header {
				padding: 14px 25px !important;
				width: 100%;
				z-index: 1;
			}

			main.dashboard_main .dashboard_content {
				padding: 40px 10px;
				.event_overview_section {
					margin: 0;
					.all_events {
						.all_events_row {
							.controls a,
							.controls button.create_event_btn span,
							.dropdown .dropdown_group,
							.dropdown span {
								display: none;
							}
						}
						.event_card_wrapper {
							#dropdown,
							.event_card .card_content .card_row_1 img {
								display: none;
							}

							.event_card {
								width: 100%;
								height: 70px;
								padding: 6px 5px 10px;
								border: 1px solid #5455660d;
								.card_content {
									width: 100%;
								}
								.card_header {
									display: flex;
									width: 2px;
									padding: 0;
									height: 12px;
									position: absolute;
									top: 15px;
									left: 10px;
								}
							}
						}
						.events_grid {
							display: flex;
							flex-direction: column;
							gap: 16px;
							height: unset;
							width: 100%;
							padding: 16px;
							margin: 0px;
							width: 94%;
							margin: 10px;
						}
					}
				}
				.homepage {
					margin: 0;
					.event_top_view {
						.event_overview_top_bar {
							flex-direction: column;
							align-items: flex-start;
							gap: 10px;
							font-size: 10px;
							.dropdown.pick_event_dropdown .dropdown_toggle .pick_an_event {
								font-size: 12px;
							}
							.controls {
								width: 100%;
								justify-content: space-between;
								.ceremony button.dropdown_toggle {
									font-size: 10px;
									text-wrap: nowrap;
									white-space: nowrap;
								}
								button.create_ceremony {
									gap: 0;
									width: 32px;
									height: 32px;
									padding: 0;
									span {
										display: none;
									}
								}
								.control_group {
									display: flex;
									align-items: center;
									gap: 10px;
									.three_dots {
										button.dropdown_toggle {
											padding: 0px;
											height: 32px;
											width: 32px;
										}
									}
								}
							}
						}
						.info_cards {
							.card_img {
								width: 100%;
							}
							.card_group {
								grid-template-columns: repeat(2, 1fr);
								width: 100%;
								.card {
									padding: 12px;
									.row h4 {
										font-size: 14px;
									}
								}
								.card.third .weather span h6 {
									font-size: 8px;
								}
							}
						}
					}
					.grid_view {
						display: flex;
						flex-direction: column;
						.item_view {
							height: fit-content;
							min-height: fit-content;
							padding: 16px;
							.item_group {
								.budget,
								.default_checklist_item {
									gap: 10px;
								}
								.item.budget_list_view,
								.default_checklist_item .item.budget_list_view {
									padding: 8px;
									height: 60px;
								}
								.item p,
								.default_checklist_item .item p {
									font-size: 12px;
								}
								.item .text p,
								.item .text time {
									font-size: 10px;
								}
								.item .text {
									margin-left: 0;
								}
							}
							.row + p {
								margin-top: 10px;
							}
						}
						.todo_body {
							height: fit-content;
							min-height: fit-content;
							.checklist_item {
								gap: 10px;
								.checklist_actions button:first-child,
								.checklist_actions button:last-child,
								.period .no_priority {
									display: none;
								}
								.checklist_wrapper {
									gap: 10px;
									height: unset;
								}
								div h6 {
									font-size: 12px;
								}
							}
						}
					}
					.tips_wrapper .tips_ideas .tips_group a {
						font-size: 10px;
						height: 28px;
						padding: 0px 6px;
					}
				}
			}
		}
	}
}
